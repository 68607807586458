
import { Vue, Component } from "vue-property-decorator";
import sBackgroundImg from "@/assets/img/hero/h4.jpg";
import PageTitle from "@/components/common/PageTitle.vue";
import CourseListing from "@/modules/courses/components/CourseListing.vue";
import { CourseData } from "@planetadeleste/vue-mc-learning";
import { EventBus } from "@/services/event-bus";

@Component({
  components: { PageTitle, CourseListing },
})
export default class CoursesList extends Vue {
  bgImg: string | null = null;

  mounted(): void {
    this.$nextTick(() => {
      this.bgImg = sBackgroundImg;
      EventBus.on("courses-loaded", this.setBgFromCourses);
    });
  }

  beforeDestroy(): void {
    EventBus.off("courses-load");
  }

  setBgFromCourses(arCourses: CourseData[]): void {
    if (this.$_.isEmpty(arCourses)) {
      return;
    }

    const obCourse = this.$_.chain(arCourses)
      .filter((obCourse) => !!obCourse.preview_image)
      .shuffle()
      .first()
      .value();

    if (obCourse) {
      this.bgImg = obCourse.preview_image;
    }
  }
}
